.u-overflow-visible { overflow: visible; }
.u-overflow-hidden { overflow: hidden; }
.u-overflow-scroll { overflow: scroll; }
.u-overflow-auto { overflow: auto; }

.u-overflow-x-visible { overflow-x: visible; }
.u-overflow-x-hidden { overflow-x: hidden; }
.u-overflow-x-scroll { overflow-x: scroll; }
.u-overflow-x-auto { overflow-x: auto; }

.u-overflow-y-visible { overflow-y: visible; }
.u-overflow-y-hidden { overflow-y: hidden; }
.u-overflow-y-scroll { overflow-y: scroll; }
.u-overflow-y-auto { overflow-y: auto; }

@include media-breakpoint-only(xs) { }
@include media-breakpoint-only(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }

