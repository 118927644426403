.u-aspect-ratio {
  height: 0;
  position: relative;
}

.u-aspect-ratio--16x9 { padding-bottom: 56.25%; }
.u-aspect-ratio--9x16 { padding-bottom: 177.77%; }

.u-aspect-ratio--4x3 {  padding-bottom: 75%; }
.u-aspect-ratio--3x4 {  padding-bottom: 133.33%; }

.u-aspect-ratio--6x4 {  padding-bottom: 66.6%; }
.u-aspect-ratio--4x6 {  padding-bottom: 150%; }

.u-aspect-ratio--8x5 {  padding-bottom: 62.5%; }
.u-aspect-ratio--5x8 {  padding-bottom: 160%; }

.u-aspect-ratio--7x5 {  padding-bottom: 71.42%; }
.u-aspect-ratio--5x7 {  padding-bottom: 140%; }

.u-aspect-ratio--1x1 {  padding-bottom: 100%; }

.u-aspect-ratio--object {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.u-overflow-container {
  overflow-y: scroll;
}

.u-center {
  margin-right: auto;
  margin-left: auto;
}
