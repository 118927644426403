.u-tracked       { letter-spacing:  .1em; }
.u-tracked-tight { letter-spacing: -.05em; }
.u-tracked-mega  { letter-spacing:  .25em; }


@include media-breakpoint-only(xs) {
  .u-tracked-xs       { letter-spacing:  .1em; }
  .u-tracked-tight-xs { letter-spacing: -.05em; }
  .u-tracked-mega-xs  { letter-spacing:  .25em; }
}

@include media-breakpoint-only(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
