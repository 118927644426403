.u-flex { display: flex; }
.u-inline-flex { display: inline-flex; }

/* 1. Fix for Chrome 44 bug.
 * https://code.google.com/p/chromium/issues/detail?id=506893 */
.u-flex-auto {
  flex: 1 1 auto;
  min-width: 0; /* 1 */
  min-height: 0; /* 1 */
}

.u-flex-none { flex: none; }

.u-flex-column  { flex-direction: column; }
.u-flex-row     { flex-direction: row; }
.u-flex-wrap    { flex-wrap: wrap; }

.u-items-start    { align-items: flex-start; }
.u-items-end      { align-items: flex-end; }
.u-items-center   { align-items: center; }
.u-items-baseline { align-items: baseline; }
.u-items-stretch  { align-items: stretch; }

.u-self-start    { align-self: flex-start; }
.u-self-end      { align-self: flex-end; }
.u-self-center   { align-self: center; }
.u-self-baseline { align-self: baseline; }
.u-self-stretch  { align-self: stretch; }

.u-justify-start   { justify-content: flex-start; }
.u-justify-end     { justify-content: flex-end; }
.u-justify-center  { justify-content: center; }
.u-justify-between { justify-content: space-between; }
.u-justify-around  { justify-content: space-around; }

.u-content-start   { align-content: flex-start; }
.u-content-end     { align-content: flex-end; }
.u-content-center  { align-content: center; }
.u-content-between { align-content: space-between; }
.u-content-around  { align-content: space-around; }
.u-content-stretch { align-content: stretch; }

.u-order-0 { order: 0; }
.u-order-1 { order: 1; }
.u-order-2 { order: 2; }
.u-order-3 { order: 3; }
.u-order-4 { order: 4; }
.u-order-5 { order: 5; }
.u-order-6 { order: 6; }
.u-order-7 { order: 7; }
.u-order-8 { order: 8; }
.u-order-last { order: 99999; }



@include media-breakpoint-only(xs) {
  .u-flex-xs { display: flex; }
  .u-inline-flex-xs { display: inline-flex; }

  /* 1. Fix for Chrome 44 bug.
  * https://code.google.com/p/chromium/issues/detail?id=506893 */
  .u-flex-auto-xs {
    flex: 1 1 auto;
    min-width: 0; /* 1 */
    min-height: 0; /* 1 */
  }

  .u-flex-none-xs { flex: none; }

  .u-flex-column-xs  { flex-direction: column; }
  .u-flex-row-xs     { flex-direction: row; }
  .u-flex-wrap-xs    { flex-wrap: wrap; }

  .u-items-start-xs    { align-items: flex-start; }
  .u-items-end-xs      { align-items: flex-end; }
  .u-items-center-xs   { align-items: center; }
  .u-items-baseline-xs { align-items: baseline; }
  .u-items-stretch-xs  { align-items: stretch; }

  .u-self-start-xs    { align-self: flex-start; }
  .u-self-end-xs      { align-self: flex-end; }
  .u-self-center-xs   { align-self: center; }
  .u-self-baseline-xs { align-self: baseline; }
  .u-self-stretch-xs  { align-self: stretch; }

  .u-justify-start-xs   { justify-content: flex-start; }
  .u-justify-end-xs     { justify-content: flex-end; }
  .u-justify-center-xs  { justify-content: center; }
  .u-justify-between-xs { justify-content: space-between; }
  .u-justify-around-xs  { justify-content: space-around; }

  .u-content-start-xs   { align-content: flex-start; }
  .u-content-end-xs     { align-content: flex-end; }
  .u-content-center-xs  { align-content: center; }
  .u-content-between-xs { align-content: space-between; }
  .u-content-around-xs  { align-content: space-around; }
  .u-content-stretch-xs { align-content: stretch; }

  .u-order-0-xs { order: 0; }
  .u-order-1-xs { order: 1; }
  .u-order-2-xs { order: 2; }
  .u-order-3-xs { order: 3; }
  .u-order-4-xs { order: 4; }
  .u-order-5-xs { order: 5; }
  .u-order-6-xs { order: 6; }
  .u-order-7-xs { order: 7; }
  .u-order-8-xs { order: 8; }
  .u-order-last-xs { order: 99999; }
}
@include media-breakpoint-up(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
