.u-h1 { height: 1rem; }
.u-h2 { height: 2rem; }
.u-h3 { height: 4rem; }
.u-h4 { height: 8rem; }
.u-h5 { height: 16rem; }

/* Height Percentages - Based off of height of parent */

.u-h-25 {  height:  25%; }
.u-h-50 {  height:  50%; }
.u-h-75 {  height:  75%; }
.u-h-100 { height: 100%; }

.u-min-h-100, %u-min-h-100 { min-height: 100%; }

/* Screen Height Percentage */

.u-vh-25 {  height:  25vh; }
.u-vh-50 {  height:  50vh; }
.u-vh-75 {  height:  75vh; }
.u-vh-100 { height: 100vh; }

.u-min-vh-100 { min-height: 100vh; }


/* String Properties */

.u-h-auto {     height: auto; }
.u-h-inherit {  height: inherit; }

@include media-breakpoint-only(xs) {
  .u-h1-xs { height: 1rem; }
  .u-h2-xs { height: 2rem; }
  .u-h3-xs { height: 4rem; }
  .u-h4-xs { height: 8rem; }
  .u-h5-xs { height: 16rem; }

  /* Height Percentages - Based off of height of parent */

  .u-h-25-xs {  height:  25%; }
  .u-h-50-xs {  height:  50%; }
  .u-h-75-xs-xs {  height:  75%; }
  .u-h-100 { height: 100%; }

  .u-min-h-100-xs { min-height: 100%; }

  /* Screen Height Percentage */

  .u-vh-25-xs {  height:  25vh; }
  .u-vh-50-xs {  height:  50vh; }
  .u-vh-75-xs {  height:  75vh; }
  .u-vh-100-xs { height: 100vh; }

  .u-min-vh-100-xs { min-height: 100vh; }


  /* String Properties */

  .u-h-auto-xs {     height: auto; }
  .u-h-inherit-xs {  height: inherit; }
}

@include media-breakpoint-only(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
