.u-bw0 { border-width: 0; }
.u-bw1 { border-width: 1px; }
.u-bw2 { border-width: 2px; }
.u-bw3 { border-width: 3px; }
.u-bw4 { border-width: 4px; }
.u-bw5 { border-width: 5px; }

/* Resets */
.u-bt-0 { border-top-width: 0; }
.u-br-0 { border-right-width: 0; }
.u-bb-0 { border-bottom-width: 0; }
.u-bl-0 { border-left-width: 0; }

@include media-breakpoint-up(sm) {
  .u-bw0-sm-up { border-width: 0; }
  .u-bw1-sm-up { border-width: 1px; }
  .u-bw2-sm-up { border-width: 2px; }
  .u-bw3-sm-up { border-width: 3px; }
  .u-bw4-sm-up { border-width: 4px; }
  .u-bw5-sm-up { border-width: 5px; }

  /* Resets */
  .u-bt-0-sm-up { border-top-width: 0; }
  .u-br-0-sm-up { border-right-width: 0; }
  .u-bb-0-sm-up { border-bottom-width: 0; }
  .u-bl-0-sm-up { border-left-width: 0; }
}

@include media-breakpoint-only(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
