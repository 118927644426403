//<div class="o-spinner">
//  <div class="bounce1"></div>
//  <div class="bounce2"></div>
//  <div class="bounce3"></div>
//</div>


.o-spinner {
	margin-left: auto;
	margin-right: auto;
	width: 50px;
	text-align: center;
	letter-spacing: 0;
}

.o-spinner > div {
	width: 6px;
	height: 6px;
	background-color: #333;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	opacity: 0.5;
}

.o-spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.o-spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% { -webkit-transform: scale(0) }
	40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0);
		transform: scale(0);
		} 40% {
			-webkit-transform: scale(1.0);
			transform: scale(1.0);
		}
	}

.o-spiner--with-text {
	margin-left: 0;
	width: 100%;
	text-align: left;
	p{
		display: inline-block;
		margin-left: 5px;
	}
}

// Sizes
.o-spinner--xs{
	width: auto;
	> div{
		width: 4px;
		height: 4px;
	}
}

.o-spinner--white > div{
		background-color: #fff;
		opacity: 1;
}
