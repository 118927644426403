.u-link {
  text-decoration: none;
  transition: color .15s ease-in;
}

.u-link:link,
.u-link:visited {
  transition: color .15s ease-in;
}
.u-link:hover   {
  transition: color .15s ease-in;
}
.u-link:active  {
  transition: color .15s ease-in;
}
.u-link:focus   {
  transition: color .15s ease-in;
  outline: 1px dotted currentColor;
}

@include media-breakpoint-only(xs) { }
@include media-breakpoint-only(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
