.u-tl  { text-align: left; }
.u-tr  { text-align: right; }
.u-tc  { text-align: center; }

@include media-breakpoint-only(xs) {
  .u-tl-xs  { text-align: left; }
  .u-tr  { text-align: right; }
  .u-tc  { text-align: center; }
}

@include media-breakpoint-only(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
