.u-ttc { text-transform: capitalize; }
.u-ttl { text-transform: lowercase; }
.u-ttu { text-transform: uppercase; }
.u-ttn { text-transform: none; }

@include media-breakpoint-only(xs) {
  .u-ttc-xs { text-transform: capitalize; }
  .u-ttl-xs { text-transform: lowercase; }
  .u-ttu-xs { text-transform: uppercase; }
  .u-ttn-xs { text-transform: none; }
}

@include media-breakpoint-only(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
