.u-dn {              display: none; }
.u-di {              display: inline; }
.u-db {              display: block; }
.u-dib {             display: inline-block; }
.u-dit {             display: inline-table; }
.u-dt {              display: table; }
.u-dtc {             display: table-cell; }
.u-dt-row {          display: table-row; }
.u-dt-row-group {    display: table-row-group; }
.u-dt-column {       display: table-column; }
.u-dt-column-group { display: table-column-group; }

.u-dt--fixed {
  table-layout: fixed;
  width: 100%;
}

@include media-breakpoint-only(sm) {
  .u-dn-sm {              display: none; }
  .u-di-sm {              display: inline; }
  .u-db-sm {              display: block; }
  .u-dib-sm {             display: inline-block; }
  .u-dit-sm {             display: inline-table; }
  .u-dt-sm {              display: table; }
  .u-dtc-sm {             display: table-cell; }
  .u-dt-row-sm {          display: table-row; }
  .u-dt-row-group-sm {    display: table-row-group; }
  .u-dt-column-sm {       display: table-column; }
  .u-dt-column-group-sm { display: table-column-group; }

  .u-dt--fixed-sm {
    table-layout: fixed;
    width: 100%;
  }
}

@include media-breakpoint-only(md) {
  .u-dn-md {              display: none; }
  .u-di-md {              display: inline; }
  .u-db-md {              display: block; }
  .u-dib-md {             display: inline-block; }
  .u-dit-md {             display: inline-table; }
  .u-dt-md {              display: table; }
  .u-dtc-md {             display: table-cell; }
  .u-dt-row-md {          display: table-row; }
  .u-dt-row-group-md {    display: table-row-group; }
  .u-dt-column-md {       display: table-column; }
  .u-dt-column-group-md { display: table-column-group; }

  .u-dt--fixed-md {
    table-layout: fixed;
    width: 100%;
  }
}

@include media-breakpoint-only(lg) {
  .u-dn-lg {              display: none; }
  .u-di-lg {              display: inline; }
  .u-db-lg {              display: block; }
  .u-dib-lg {             display: inline-block; }
  .u-dit-lg {             display: inline-table; }
  .u-dt-lg {              display: table; }
  .u-dtc-lg {             display: table-cell; }
  .u-dt-row-lg {          display: table-row; }
  .u-dt-row-group-lg {    display: table-row-group; }
  .u-dt-column-lg {       display: table-column; }
  .u-dt-column-group-lg { display: table-column-group; }

  .u-dt--fixed-lg {
    table-layout: fixed;
    width: 100%;
  }
}

@include media-breakpoint-only(xl) { }
