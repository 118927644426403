.u-ba { border-style: solid; border-width: 1px; }
.u-bt { border-top-style: solid; border-top-width: 1px; }
.u-br { border-right-style: solid; border-right-width: 1px; }
.u-bb { border-bottom-style: solid; border-bottom-width: 1px; }
.u-bl { border-left-style: solid; border-left-width: 1px; }
.u-bn { border-style: none; border-width: 0; }

@include media-breakpoint-only(xs) {
  .u-ba-xs { border-style: solid; border-width: 1px; }
  .u-bt-xs { border-top-style: solid; border-top-width: 1px; }
  .u-br-xs { border-right-style: solid; border-right-width: 1px; }
  .u-bb-xs { border-bottom-style: solid; border-bottom-width: 1px; }
  .u-bl-xs { border-left-style: solid; border-left-width: 1px; }
  .u-bn-xs { border-style: none; border-width: 0; }
}

@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
