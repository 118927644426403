/*
  Dim element on hover by adding the dim class.
*/
.u-dim {
  opacity: 1;
  transition: opacity .15s ease-in;
}
.u-dim:hover,
.u-dim:focus {
  opacity: .5;
  transition: opacity .15s ease-in;
}
.u-dim:active {
  opacity: .8; transition: opacity .15s ease-out;
}

/*
  Hide child & reveal on hover:
  Put the hide-child class on a parent element and any nested element with the
  child class will be hidden and displayed on hover or focus.
  <div class="hide-child">
    <div class="child"> Hidden until hover or focus </div>
    <div class="child"> Hidden until hover or focus </div>
    <div class="child"> Hidden until hover or focus </div>
    <div class="child"> Hidden until hover or focus </div>
  </div>
*/

.u-hide-child .child {
  opacity: 0;
  transition: opacity .15s ease-in;
}
.u-hide-child:hover  .child,
.u-hide-child:focus  .child,
.u-hide-child:active .child {
  opacity: 1;
  transition: opacity .15s ease-in;
}

.u-underline-hover:hover,
.u-underline-hover:focus {
  text-decoration: underline;
}

/* Add pointer on hover */

.u-pointer:hover {
  cursor: pointer;
}

/*
   Add shadow on hover.
   Performant box-shadow animation pattern from
   http://tobiasahlin.com/blog/how-to-animate-box-shadow/
*/

.u-shadow-hover::after {
  box-shadow: 0px 0px 8px 2px rgba( 0, 0, 0, .2 );
  opacity: 0;
  transition: opacity .25s ease-in-out;
}

.u-shadow-hover:hover::after,
.u-shadow-hover:focus::after {
  opacity: 1;
}

/* Combine with classes in skins and skins-pseudo for
 * thousands of different transition possibilities. */

.u-bg-animate,
.u-bg-animate:hover,
.u-bg-animate:focus {
  transition: background-color .15s ease-in-out;
}
