// Variations
.btn-black {
  @include button-variant(#fff, #000, #000);
}

.btn-outline-black {
  @include button-outline-variant(#000);
}


// Size
.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $font-size-xs, $btn-border-radius-sm);
}
