.u-lh-solid { line-height: 1; }
.u-lh-title { line-height: $headings-line-height; }
.u-lh-copy  { line-height: $line-height-base; }

@include media-breakpoint-only(xs) {
  .u-lh-solid-xs { line-height: 1; }
  .u-lh-title-xs { line-height: $headings-line-height; }
  .u-lh-copy-xs  { line-height: $line-height-base; }
}

@include media-breakpoint-only(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
