.o-long-form{
  p{
    margin-top: 1rem;
  }
}

.o-long-form-lg{
  h1{
    font-size: $font-size-h1 * 1.3;
  }
  h2{
    margin-top: 1.87rem * 2;
    font-size: $font-size-h2 * 1.3;
  }
  h3{
    margin-top: 1.87rem * 2;
    font-size: $font-size-h3 * 1.3;
  }
  h4{
    margin-top: 1.87rem * 2;
    font-size: $font-size-h4 * 1.3;
  }
  h5{
    margin-top: 1.87rem * 2;
    font-size: $font-size-h5 * 1.3;
  }
  h6{
    margin-top: 1.87rem * 2;
    font-size: $font-size-h6 * 1.3;
  }
  p{
    font-size: $font-size-base * 1.3;
    margin-top: 1.87rem;
    margin-bottom: 1.87rem;
  }
}
