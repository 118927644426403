.u-o-100 { opacity: 1;    }
.u-o-90  { opacity: .9;   }
.u-o-80  { opacity: .8;   }
.u-o-70  { opacity: .7;   }
.u-o-60  { opacity: .6;   }
.u-o-50  { opacity: .5;   }
.u-o-40  { opacity: .4;   }
.u-o-30  { opacity: .3;   }
.u-o-20  { opacity: .2;   }
.u-o-10  { opacity: .1;   }
.u-o-05  { opacity: .05;  }
.u-o-025 { opacity: .025; }
.u-o-0   { opacity: 0; }
