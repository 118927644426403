.u-z-0 { z-index: 0; }
.u-z-1 { z-index: 1; }
.u-z-2 { z-index: 2; }
.u-z-3 { z-index: 3; }
.u-z-4 { z-index: 4; }
.u-z-5 { z-index: 5; }

.u-z-999 { z-index: 999; }
.u-z-9999 { z-index: 9999; }

.u-z-max {
  z-index: 2147483647;
}

.u-z-inherit { z-index: inherit; }
.u-z-initial { z-index: initial; }
.u-z-unset { z-index: unset; }

@include media-breakpoint-only(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
