.u-static { position: static; }
.u-relative  { position: relative; }
.u-absolute  { position: absolute; }
.u-fixed  { position: fixed; }

@include media-breakpoint-only(xs) {
  .u-static-xs { position: static; }
  .u-relative-xs  { position: relative; }
  .u-absolute-xs  { position: absolute; }
  .u-fixed-xs  { position: fixed; }
}
@include media-breakpoint-only(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
