.u-b--black {        border-color: #000; }
.u-b--white {        border-color: #fff; }
.u-b--primary {        border-color: $brand-primary; }
.u-b--success {        border-color: $brand-success; }

.u-b--transparent { border-color: transparent; }

.u-b--black-90 {   border-color: rgba(0,0,0,.9); }
.u-b--black-80 {   border-color: rgba(0,0,0,.8); }
.u-b--black-70 {   border-color: rgba(0,0,0,.7); }
.u-b--black-60 {   border-color: rgba(0,0,0,.6); }
.u-b--black-50 {   border-color: rgba(0,0,0,.5); }
.u-b--black-40 {   border-color: rgba(0,0,0,.4); }
.u-b--black-30 {   border-color: rgba(0,0,0,.3); }
.u-b--black-20 {   border-color: rgba(0,0,0,.2); }
.u-b--black-10 {   border-color: rgba(0,0,0,.1); }
.u-b--black-05 {   border-color: rgba(0,0,0,.05); }
.u-b--black-025 {   border-color: rgba(0,0,0,.025); }
.u-b--black-0125 {   border-color: rgba(0,0,0,.0125); }
