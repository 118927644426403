.u-f-6,
.u-f-headline {
  font-size: $display1-size;
}
.u-f-5,
.u-f-subheadline {
  font-size: $display2-size;
}


/* Type Scale */

.u-f1 { font-size: $font-size-h1; }
.u-f2 { font-size: $font-size-h2; }
.u-f3 { font-size: $font-size-h3; }
.u-f4 { font-size: $font-size-lg; }
.u-f5 { font-size: $font-size-base; }
.u-f6 { font-size: $font-size-sm; }
.u-f7 { font-size: $font-size-xs; }

@include media-breakpoint-only(xs) {
  .u-f-6-xs,
  .u-f-headline-xs {
    font-size: $display1-size;
  }
  .u-f-5-xs,
  .u-f-subheadline-xs {
    font-size: $display2-size;
  }


  /* Type Scale */

  .u-f1 { font-size: $font-size-h1; }
  .u-f2 { font-size: $font-size-h2; }
  .u-f3 { font-size: $font-size-h3; }
  .u-f4-xs { font-size: $font-size-lg; }
  .u-f5-xs { font-size: $font-size-base; }
  .u-f6-xs { font-size: $font-size-sm; }
  .u-f7-xs { font-size: $font-size-xs; }
}

@include media-breakpoint-only(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
