.u-top-0    { top:    0; }
.u-right-0  { right:  0; }
.u-bottom-0 { bottom: 0; }
.u-left-0   { left:   0; }

.u-top-1    { top:    1rem; }
.u-right-1  { right:  1rem; }
.u-bottom-1 { bottom: 1rem; }
.u-left-1   { left:   1rem; }

.u-top-2    { top:    2rem; }
.u-right-2  { right:  2rem; }
.u-bottom-2 { bottom: 2rem; }
.u-left-2   { left:   2rem; }

.u-top--1    { top:    -1rem; }
.u-right--1  { right:  -1rem; }
.u-bottom--1 { bottom: -1rem; }
.u-left--1   { left:   -1rem; }

.u-top--2    { top:    -2rem; }
.u-right--2  { right:  -2rem; }
.u-bottom--2 { bottom: -2rem; }
.u-left--2   { left:   -2rem; }


.u-absolute--fill {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@include media-breakpoint-up(sm) {}

@include media-breakpoint-only(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
