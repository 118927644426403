.u-normal { font-weight: normal; }
.u-b      { font-weight: bold; }
.u-fw1    { font-weight: 100; }
.u-fw2    { font-weight: 200; }
.u-fw3    { font-weight: 300; }
.u-fw4    { font-weight: 400; }
.u-fw5    { font-weight: 500; }
.u-fw6    { font-weight: 600; }
.u-fw7    { font-weight: 700; }
.u-fw8    { font-weight: 800; }
.u-fw9    { font-weight: 900; }
