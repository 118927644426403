.u-v-base     { vertical-align: baseline; }
.u-v-mid      { vertical-align: middle; }
.u-v-top      { vertical-align: top; }
.u-v-btm      { vertical-align: bottom; }


@include media-breakpoint-only(sm) {
  .u-v-base-sm     { vertical-align: baseline; }
  .u-v-mid-sm      { vertical-align: middle; }
  .u-v-top-sm      { vertical-align: top; }
  .u-v-btm-sm      { vertical-align: bottom; }
}

@include media-breakpoint-only(md) {
  .u-v-base-md     { vertical-align: baseline; }
  .u-v-mid-md      { vertical-align: middle; }
  .u-v-top-md      { vertical-align: top; }
  .u-v-btm-md      { vertical-align: bottom; }
}
@include media-breakpoint-only(lg) {
  .u-v-base-lg     { vertical-align: baseline; }
  .u-v-mid-lg      { vertical-align: middle; }
  .u-v-top-lg      { vertical-align: top; }
  .u-v-btm-lg      { vertical-align: bottom; }
}

@include media-breakpoint-only(xl) { }
