// Padding // --------------------------------------------------

.u-pt-0{ padding-top: 0px; }
.u-pt-5{ padding-top: 5px; }
.u-pt-10{ padding-top: 10px; }
.u-pt-20{ padding-top: 20px; }
.u-pt-30{ padding-top: 30px; }
.u-pt-40{ padding-top: 40px; }
.u-pt-50{ padding-top: 50px; }
.u-pt-60{ padding-top: 60px; }
.u-pt-70{ padding-top: 70px; }
.u-pt-80{ padding-top: 80px; }
.u-pt-90{ padding-top: 90px; }
.u-pt-100{ padding-top: 100px; }

.u-pb-0{ padding-bottom: 0px; }
.u-pb-5{ padding-bottom: 5px; }
.u-pb-10{ padding-bottom: 10px; }
.u-pb-20{ padding-bottom: 20px; }
.u-pb-30{ padding-bottom: 30px; }
.u-pb-40{ padding-bottom: 40px; }
.u-pb-50{ padding-bottom: 50px; }
.u-pb-60{ padding-bottom: 60px; }
.u-pb-70{ padding-bottom: 70px; }
.u-pb-80{ padding-bottom: 80px; }
.u-pb-90{ padding-bottom: 90px; }
.u-pb-100{ padding-bottom: 100px; }

.u-pl-0{ padding-left: 0px; }
.u-pl-10{ padding-left: 10px; }
.u-pl-20{ padding-left: 20px; }
.u-pl-30{ padding-left: 30px; }
.u-pl-40{ padding-left: 40px; }
.u-pl-50{ padding-left: 50px; }
.u-pl-60{ padding-left: 60px; }
.u-pl-70{ padding-left: 70px; }
.u-pl-80{ padding-left: 80px; }
.u-pl-90{ padding-left: 90px; }
.u-pl-100{ padding-left: 100px; }

.u-pr-0{ padding-right: 0px; }
.u-pr-5{ padding-right: 5px; }
.u-pr-10{ padding-right: 10px; }
.u-pr-20{ padding-right: 20px; }
.u-pr-30{ padding-right: 30px; }
.u-pr-40{ padding-right: 40px; }
.u-pr-50{ padding-right: 50px; }
.u-pr-60{ padding-right: 60px; }
.u-pr-70{ padding-right: 70px; }
.u-pr-80{ padding-right: 80px; }
.u-pr-90{ padding-right: 90px; }
.u-pr-100{ padding-right: 100px; }

.u-p-all-0{ padding: 0px; }
.u-p-all-5{ padding: 5px; }
.u-p-all-10{ padding: 10px; }
.u-p-all-20{ padding: 20px; }
.u-p-all-30{ padding: 30px; }
.u-p-all-40{ padding: 40px; }
.u-p-all-50{ padding: 50px; }
.u-p-all-60{ padding: 60px; }
.u-p-all-70{ padding: 70px; }
.u-p-all-80{ padding: 80px; }
.u-p-all-90{ padding: 90px; }
.u-p-all-100{ padding: 100px; }

// Margins // --------------------------------------------------

.u-mt-0{ margin-top: 0px; }
.u-mt-5{ margin-top: 5px; }
.u-mt-10{ margin-top: 10px; }
.u-mt-15{ margin-top: 15px; }
.u-mt-20{ margin-top: 20px; }
.u-mt-30{ margin-top: 30px; }
.u-mt-40{ margin-top: 40px; }
.u-mt-50{ margin-top: 50px; }
.u-mt-60{ margin-top: 60px; }
.u-mt-70{ margin-top: 70px; }
.u-mt-80{ margin-top: 80px; }
.u-mt-90{ margin-top: 90px; }
.u-mt-100{ margin-top: 100px; }

.u-mb-0{ margin-bottom: 0px; }
.u-mb-5{ margin-bottom: 5px; }
.u-mb-10{ margin-bottom: 10px; }
.u-mb-15{ margin-bottom: 15px; }
.u-mb-20{ margin-bottom: 20px; }
.u-mb-30{ margin-bottom: 30px; }
.u-mb-40{ margin-bottom: 40px; }
.u-mb-50{ margin-bottom: 50px; }
.u-mb-60{ margin-bottom: 60px; }
.u-mb-70{ margin-bottom: 70px; }
.u-mb-80{ margin-bottom: 80px; }
.u-mb-90{ margin-bottom: 90px; }
.u-mb-100{ margin-bottom: 100px; }

.u-ml-0{ margin-left: 0px; }
.u-ml-5{ margin-left: 5px; }
.u-ml-10{ margin-left: 10px; }
.u-ml-20{ margin-left: 20px; }
.u-ml-30{ margin-left: 30px; }
.u-ml-40{ margin-left: 40px; }
.u-ml-50{ margin-left: 50px; }
.u-ml-60{ margin-left: 60px; }
.u-ml-70{ margin-left: 70px; }
.u-ml-80{ margin-left: 80px; }
.u-ml-90{ margin-left: 90px; }
.u-ml-100{ margin-left: 100px; }


.u-ml-minus-40{ margin-left: -40px; }

.u-mr-0{ margin-right: 0px; }
.u-mr-5{ margin-right: 5px; }
.u-mr-10{ margin-right: 10px; }
.u-mr-20{ margin-right: 20px; }
.u-mr-30{ margin-right: 30px; }
.u-mr-40{ margin-right: 40px; }
.u-mr-50{ margin-right: 50px; }
.u-mr-60{ margin-right: 60px; }
.u-mr-70{ margin-right: 70px; }
.u-mr-80{ margin-right: 80px; }
.u-mr-90{ margin-right: 90px; }
.u-mr-100{ margin-right: 100px; }

.u-mr-minus-40{ margin-right: -40px; }

.u-m-all-0{ margin: 0px; }
.u-m-all-10{ margin: 10px; }
.u-m-all-20{ margin: 20px; }
.u-m-all-30{ margin: 30px; }
.u-m-all-40{ margin: 40px; }
.u-m-all-50{ margin: 50px; }
.u-m-all-60{ margin: 60px; }
.u-m-all-70{ margin: 70px; }
.u-m-all-80{ margin: 80px; }
.u-m-all-90{ margin: 90px; }
.u-m-all-100{ margin: 100px; }

// Padding Responsive // --------------------------------------------------

// Small screen - XS
@include media-breakpoint-only(xs) {
  .u-pt-xs-0{ padding-top: 0; }
  .u-pt-xs-5{ padding-top: 5px; }
  .u-pt-xs-10{ padding-top: 10px; }
  .u-pt-xs-20{ padding-top: 20px; }
  .u-pt-xs-30{ padding-top: 30px; }
  .u-pt-xs-40{ padding-top: 40px; }
  .u-pt-xs-50{ padding-top: 50px; }
  .u-pt-xs-60{ padding-top: 60px; }
  .u-pt-xs-70{ padding-top: 70px; }
  .u-pt-xs-80{ padding-top: 80px; }
  .u-pt-xs-90{ padding-top: 90px; }
  .u-pt-xs-100{ padding-top: 100px; }

  .u-pb-xs-0{ padding-bottom: 0; }
  .u-pb-xs-5{ padding-bottom: 5px; }
  .u-pb-xs-10{ padding-bottom: 10px; }
  .u-pb-xs-20{ padding-bottom: 20px; }
  .u-pb-xs-30{ padding-bottom: 30px; }
  .u-pb-xs-40{ padding-bottom: 40px; }
  .u-pb-xs-50{ padding-bottom: 50px; }
  .u-pb-xs-60{ padding-bottom: 60px; }
  .u-pb-xs-70{ padding-bottom: 70px; }
  .u-pb-xs-80{ padding-bottom: 80px; }
  .u-pb-xs-90{ padding-bottom: 90px; }
  .u-pb-xs-100{ padding-bottom: 100px; }

  .u-pl-xs-0{ padding-left: 0; }
  .u-pl-xs-5{ padding-left: 5px; }
  .u-pl-xs-10{ padding-left: 10px; }
  .u-pl-xs-20{ padding-left: 20px; }
  .u-pl-xs-30{ padding-left: 30px; }
  .u-pl-xs-40{ padding-left: 40px; }
  .u-pl-xs-50{ padding-left: 50px; }
  .u-pl-xs-60{ padding-left: 60px; }
  .u-pl-xs-70{ padding-left: 70px; }
  .u-pl-xs-80{ padding-left: 80px; }
  .u-pl-xs-90{ padding-left: 90px; }
  .u-pl-xs-100{ padding-left: 100px; }

  .u-pr-xs-0{ padding-right: 0; }
  .u-pr-xs-5{ padding-right: 5px; }
  .u-pr-xs-10{ padding-right: 10px; }
  .u-pr-xs-20{ padding-right: 20px; }
  .u-pr-xs-30{ padding-right: 30px; }
  .u-pr-xs-40{ padding-right: 40px; }
  .u-pr-xs-50{ padding-right: 50px; }
  .u-pr-xs-60{ padding-right: 60px; }
  .u-pr-xs-70{ padding-right: 70px; }
  .u-pr-xs-80{ padding-right: 80px; }
  .u-pr-xs-90{ padding-right: 90px; }
  .u-pr-xs-100{ padding-right: 100px; }

  .u-p-all-xs-0{ padding: 0; }
  .u-p-all-xs-5{ padding: 5px; }
  .u-p-all-xs-10{ padding: 10px; }
  .u-p-all-xs-20{ padding: 20px; }
  .u-p-all-xs-30{ padding: 30px; }
  .u-p-all-xs-40{ padding: 40px; }
  .u-p-all-xs-50{ padding: 50px; }
  .u-p-all-xs-60{ padding: 60px; }
  .u-p-all-xs-70{ padding: 70px; }
  .u-p-all-xs-80{ padding: 80px; }
  .u-p-all-xs-90{ padding: 90px; }
  .u-p-all-xs-100{ padding: 100px; }
}

// Margins Responsive // --------------------------------------------------

// Small screen - XS
@include media-breakpoint-only(xs) {
  .u-mt-xs-0{ margin-top: 0; }
  .u-mt-xs-5{ margin-top: 5px; }
  .u-mt-xs-10{ margin-top: 10px; }
  .u-mt-xs-20{ margin-top: 20px; }
  .u-mt-xs-30{ margin-top: 30px; }
  .u-mt-xs-40{ margin-top: 40px; }
  .u-mt-xs-50{ margin-top: 50px; }
  .u-mt-xs-60{ margin-top: 60px; }
  .u-mt-xs-70{ margin-top: 70px; }
  .u-mt-xs-80{ margin-top: 80px; }
  .u-mt-xs-90{ margin-top: 90px; }
  .u-mt-xs-100{ margin-top: 100px; }

  .u-mb-xs-0{ margin-bottom: 0; }
  .u-mb-xs-5{ margin-bottom: 5px; }
  .u-mb-xs-10{ margin-bottom: 10px; }
  .u-mb-xs-20{ margin-bottom: 20px; }
  .u-mb-xs-30{ margin-bottom: 30px; }
  .u-mb-xs-40{ margin-bottom: 40px; }
  .u-mb-xs-50{ margin-bottom: 50px; }
  .u-mb-xs-60{ margin-bottom: 60px; }
  .u-mb-xs-70{ margin-bottom: 70px; }
  .u-mb-xs-80{ margin-bottom: 80px; }
  .u-mb-xs-90{ margin-bottom: 90px; }
  .u-mb-xs-100{ margin-bottom: 100px; }

  .u-ml-xs-0{ margin-left: 0; }
  .u-ml-xs-5{ margin-left: 5px; }
  .u-ml-xs-10{ margin-left: 10px; }
  .u-ml-xs-20{ margin-left: 20px; }
  .u-ml-xs-30{ margin-left: 30px; }
  .u-ml-xs-40{ margin-left: 40px; }
  .u-ml-xs-50{ margin-left: 50px; }
  .u-ml-xs-60{ margin-left: 60px; }
  .u-ml-xs-70{ margin-left: 70px; }
  .u-ml-xs-80{ margin-left: 80px; }
  .u-ml-xs-90{ margin-left: 90px; }
  .u-ml-xs-100{ margin-left: 100px; }

  .u-ml-xs-minus-20{ margin-left: -20px; }

  .u-mr-xs-0{ margin-right: 0; }
  .u-mr-xs-5{ margin-right: 5px; }
  .u-mr-xs-10{ margin-right: 10px; }
  .u-mr-xs-20{ margin-right: 20px; }
  .u-mr-xs-30{ margin-right: 30px; }
  .u-mr-xs-40{ margin-right: 40px; }
  .u-mr-xs-50{ margin-right: 50px; }
  .u-mr-xs-60{ margin-right: 60px; }
  .u-mr-xs-70{ margin-right: 70px; }
  .u-mr-xs-80{ margin-right: 80px; }
  .u-mr-xs-90{ margin-right: 90px; }
  .u-mr-xs-100{ margin-right: 100px; }

  .u-mr-xs-minus-20{ margin-right: -20px; }

  .u-m-all-xs-0{ margin: 0; }
  .u-m-all-xs-5{ margin: 5px; }
  .u-m-all-xs-10{ margin: 10px; }
  .u-m-all-xs-20{ margin: 20px; }
  .u-m-all-xs-30{ margin: 30px; }
  .u-m-all-xs-40{ margin: 40px; }
  .u-m-all-xs-50{ margin: 50px; }
  .u-m-all-xs-60{ margin: 60px; }
  .u-m-all-xs-70{ margin: 70px; }
  .u-m-all-xs-80{ margin: 80px; }
  .u-m-all-xs-90{ margin: 90px; }
  .u-m-all-xs-100{ margin: 100px; }


}
