.u-font-sans{
  font-family: font-family-sans-serif;
}

.u-font-serif{
  font-family: $font-family-serif;
}

.u-font-mono{
  font-family: $font-family-monospace;
}
