/* Max Width Percentages */

.u-mw-100  { max-width: 100%; }

/* Max Width Scale */

.u-mw1  {  max-width: 1rem; }
.u-mw2  {  max-width: 2rem; }
.u-mw3  {  max-width: 4rem; }
.u-mw4  {  max-width: 8rem; }
.u-mw5  {  max-width: 16rem; }
.u-mw6  {  max-width: 32rem; }
.u-mw7  {  max-width: 48rem; }
.u-mw8  {  max-width: 64rem; }
.u-mw9  {  max-width: 96rem; }

/* Max Width String Properties */

.u-mw-none { max-width: none; }

// custom
.u-max-w-sm{
  max-width: 540px;
}

.u-max-w-md{
  max-width: 720px;
}

.u-max-w-lg{
  max-width: 960px;
}

.u-max-w-xl{
  max-width: 1140px;
}

.u-min-w-50vw{
  min-width: 50vw;
}

.u-min-w-100vw{
  min-width: 100vw;
}

@include media-breakpoint-only(xs) { }
@include media-breakpoint-only(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
