// # Core variables and mixins

// Project
@import "bootstrap/variables";
@import "bootstrap/mixins";

// Npm
//@import "./node_modules/bootstrap/scss/custom";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

// # Reset and dependencies

// Npm
@import "./node_modules/bootstrap/scss/normalize";
@import "./node_modules/bootstrap/scss/print";

// Project
@import "bootstrap/normalize";
@import "bootstrap/print";

// # Core CSS

// Npm
@import "./node_modules/bootstrap/scss/reboot";
@import "./node_modules/bootstrap/scss/type";
@import "./node_modules/bootstrap/scss/images";
@import "./node_modules/bootstrap/scss/code";
@import "./node_modules/bootstrap/scss/grid";
@import "./node_modules/bootstrap/scss/tables";
@import "./node_modules/bootstrap/scss/forms";
@import "./node_modules/bootstrap/scss/buttons";

// Project
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";

// # Components

// Npm
@import "./node_modules/bootstrap/scss/transitions";
@import "./node_modules/bootstrap/scss/dropdown";
@import "./node_modules/bootstrap/scss/button-group";
@import "./node_modules/bootstrap/scss/input-group";
@import "./node_modules/bootstrap/scss/custom-forms";
@import "./node_modules/bootstrap/scss/nav";
@import "./node_modules/bootstrap/scss/navbar";
@import "./node_modules/bootstrap/scss/card";
@import "./node_modules/bootstrap/scss/breadcrumb";
@import "./node_modules/bootstrap/scss/pagination";
@import "./node_modules/bootstrap/scss/badge";
@import "./node_modules/bootstrap/scss/jumbotron";
@import "./node_modules/bootstrap/scss/alert";
@import "./node_modules/bootstrap/scss/progress";
@import "./node_modules/bootstrap/scss/media";
@import "./node_modules/bootstrap/scss/list-group";
@import "./node_modules/bootstrap/scss/responsive-embed";
@import "./node_modules/bootstrap/scss/close";

// Project
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/responsive-embed";
@import "bootstrap/close";


// Components w/ JavaScript

// Npm
//@import "./node_modules/bootstrap/scss/modal";
@import "./node_modules/bootstrap/scss/tooltip";
@import "./node_modules/bootstrap/scss/popover";
//@import "./node_modules/bootstrap/scss/carousel";

// Project
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";

// Utility classes

// Npm
@import "./node_modules/bootstrap/scss/utilities";

// Project
@import "bootstrap/utilities";


// # Project
//
// Plugins
@import "plugin.animate";
@import "plugin.flickity";

//
@import "object.long-form";
@import "object.spinner";
@import "general";
@import "icons";

// Tachyons utilities
@import "tachyons/spacing";
@import "tachyons/widths";
@import "tachyons/heights";
@import "tachyons/borders";
@import "tachyons/border-colors";
@import "tachyons/border-radius";
@import "tachyons/border-widths";
@import "tachyons/border-style";
@import "tachyons/display";
@import "tachyons/floats";
@import "tachyons/opacity";
@import "tachyons/word-break";
@import "tachyons/white-space";
@import "tachyons/vertical-align";
@import "tachyons/z-index";
@import "tachyons/coordinates";
@import "tachyons/box-shadow";
@import "tachyons/flexbox";
@import "tachyons/font-family";
@import "tachyons/font-weight";
@import "tachyons/letter-spacing";
@import "tachyons/line-height";
@import "tachyons/text-align";
@import "tachyons/text-transform";
@import "tachyons/text-decoration";
@import "tachyons/overflow";
@import "tachyons/position";
@import "tachyons/link";
@import "tachyons/max-widths";
@import "tachyons/type-scale";
@import "tachyons/skins";
@import "tachyons/hovers";
@import "tachyons/skins-pseudo";
@import "tachyons/utilities";
@import "tachyons/lists";
@import "tachyons/typography";
