.u-fl { float: left; }
.u-fr { float: right; }
.u-fn { float: none; }

@include media-breakpoint-only(xs) {
  .u-fl-xs { float: left; }
  .u-fr-xs { float: right; }
  .u-fn-xs { float: none; }
}

@include media-breakpoint-only(sm) {
  .u-fl-sm { float: left; }
  .u-fr-sm { float: right; }
  .u-fn-sm { float: none; }
}

@include media-breakpoint-only(md) {
  .u-fl-md { float: left; }
  .u-fr-md { float: right; }
  .u-fn-md { float: none; }
}

@include media-breakpoint-only(lg) {
  .u-fl-lg { float: left; }
  .u-fr-lg { float: right; }
  .u-fn-lg { float: none; }
}

@include media-breakpoint-only(xl) { }
