/* Text colors */

.text-black-90 {         color: rgba(0,0,0,.9) }
.text-black-80 {         color: rgba(0,0,0,.8); }
.text-black-70 {         color: rgba(0,0,0,.7); }
.text-black-60 {         color: rgba(0,0,0,.6); }
.text-black-50 {         color: rgba(0,0,0,.5); }
.text-black-40 {         color: rgba(0,0,0,.4); }
.text-black-30 {         color: rgba(0,0,0,.3); }
.text-black-20 {         color: rgba(0,0,0,.2); }
.text-black-10 {         color: rgba(0,0,0,.1); }
.text-black-05 {         color: rgba(0,0,0,.05); }
.text-black {         color: rgba(0,0,0,1); }

.text-white-90 {         color: rgba(255,255,255,.9); }
.text-white-80 {         color: rgba(255,255,255,.8); }
.text-white-70 {         color: rgba(255,255,255,.7); }
.text-white-60 {         color: rgba(255,255,255,.6); }
.text-white-50 {         color: rgba(255,255,255,.5); }
.text-white-40 {         color: rgba(255,255,255,.4); }
.text-white-30 {         color: rgba(255,255,255,.3); }
.text-white-20 {         color: rgba(255,255,255,.2); }
.text-white-10 {         color: rgba(255,255,255,.1); }
.text-white {            color: rgba(255,255,255,1); }


/* Background colors */

.bg-black-90 {         background-color: rgba(0,0,0,.9); }
.bg-black-80 {         background-color: rgba(0,0,0,.8); }
.bg-black-70 {         background-color: rgba(0,0,0,.7); }
.bg-black-60 {         background-color: rgba(0,0,0,.6); }
.bg-black-50 {         background-color: rgba(0,0,0,.5); }
.bg-black-40 {         background-color: rgba(0,0,0,.4); }
.bg-black-30 {         background-color: rgba(0,0,0,.3); }
.bg-black-20 {         background-color: rgba(0,0,0,.2); }
.bg-black-10 {         background-color: rgba(0,0,0,.1); }
.bg-black-05 {         background-color: rgba(0,0,0,.05); }
.bg-black {         background-color: rgba(0,0,0,1); }

.bg-white-90 {        background-color: rgba(255,255,255,.9); }
.bg-white-80 {        background-color: rgba(255,255,255,.8); }
.bg-white-70 {        background-color: rgba(255,255,255,.7); }
.bg-white-60 {        background-color: rgba(255,255,255,.6); }
.bg-white-50 {        background-color: rgba(255,255,255,.5); }
.bg-white-40 {        background-color: rgba(255,255,255,.4); }
.bg-white-30 {        background-color: rgba(255,255,255,.3); }
.bg-white-20 {        background-color: rgba(255,255,255,.2); }
.bg-white-10 {        background-color: rgba(255,255,255,.1); }
.bg-white {        background-color: rgba(255,255,255,1); }
