.u-w1 {    width: 1rem; }
.u-w2 {    width: 2rem; }
.u-w3 {    width: 4rem; }
.u-w4 {    width: 8rem; }
.u-w5 {    width: 16rem; }

.u-w-10 {  width:  10%; }
.u-w-20 {  width:  20%; }
.u-w-25 {  width:  25%; }
.u-w-30 {  width:  30%; }
.u-w-33 {  width:  33%; }
.u-w-34 {  width:  34%; }
.u-w-40 {  width:  40%; }
.u-w-50 {  width:  50%; }
.u-w-60 {  width:  60%; }
.u-w-70 {  width:  70%; }
.u-w-75 {  width:  75%; }
.u-w-80 {  width:  80%; }
.u-w-90 {  width:  90%; }
.u-w-100 { width: 100%; }

.u-w-third { width: calc(100% / 3); }
.u-w-two-thirds { width: calc(100% / 1.5); }
.u-w-auto { width: auto; }

@include media-breakpoint-only(xs) {
  .u-w1-xs {    width: 1rem; }
  .u-w2-xs {    width: 2rem; }
  .u-w3-xs {    width: 4rem; }
  .u-w4-xs {    width: 8rem; }
  .u-w5-xs {    width: 16rem; }

  .u-w-10-xs {  width:  10%; }
  .u-w-20-xs {  width:  20%; }
  .u-w-25-xs {  width:  25%; }
  .u-w-30-xs {  width:  30%; }
  .u-w-33-xs {  width:  33%; }
  .u-w-34-xs {  width:  34%; }
  .u-w-40-xs {  width:  40%; }
  .u-w-50-xs {  width:  50%; }
  .u-w-60-xs {  width:  60%; }
  .u-w-70-xs {  width:  70%; }
  .u-w-75-xs {  width:  75%; }
  .u-w-80-xs {  width:  80%; }
  .u-w-90-xs {  width:  90%; }
  .u-w-100-xs { width: 100%; }

  .u-w-third-xs { width: calc(100% / 3); }
  .u-w-two-thirds-xs { width: calc(100% / 1.5); }
  .u-w-auto-xs { width: auto; }
}

@include media-breakpoint-only(sm) {
  .u-w1-sm {    width: 1rem; }
  .u-w2-sm {    width: 2rem; }
  .u-w3-sm {    width: 4rem; }
  .u-w4-sm {    width: 8rem; }
  .u-w5-sm {    width: 16rem; }

  .u-w-10-sm {  width:  10%; }
  .u-w-20-sm {  width:  20%; }
  .u-w-25-sm {  width:  25%; }
  .u-w-30-sm {  width:  30%; }
  .u-w-33-sm {  width:  33%; }
  .u-w-34-sm {  width:  34%; }
  .u-w-40-sm {  width:  40%; }
  .u-w-50-sm {  width:  50%; }
  .u-w-60-sm {  width:  60%; }
  .u-w-70-sm {  width:  70%; }
  .u-w-75-sm {  width:  75%; }
  .u-w-80-sm {  width:  80%; }
  .u-w-90-sm {  width:  90%; }
  .u-w-100-sm { width: 100%; }
}

@include media-breakpoint-only(md) {
  .u-w1-md {    width: 1rem; }
  .u-w2-md {    width: 2rem; }
  .u-w3-md {    width: 4rem; }
  .u-w4-md {    width: 8rem; }
  .u-w5-md {    width: 16rem; }

  .u-w-10-md {  width:  10%; }
  .u-w-20-md {  width:  20%; }
  .u-w-25-md {  width:  25%; }
  .u-w-30-md {  width:  30%; }
  .u-w-33-md {  width:  33%; }
  .u-w-34-md {  width:  34%; }
  .u-w-40-md {  width:  40%; }
  .u-w-50-md {  width:  50%; }
  .u-w-60-md {  width:  60%; }
  .u-w-70-md {  width:  70%; }
  .u-w-75-md {  width:  75%; }
  .u-w-80-md {  width:  80%; }
  .u-w-90-md {  width:  90%; }
  .u-w-100-md { width: 100%; }
}

@include media-breakpoint-up(lg) {
  .u-w1-lg {    width: 1rem; }
  .u-w2-lg {    width: 2rem; }
  .u-w3-lg {    width: 4rem; }
  .u-w4-lg {    width: 8rem; }
  .u-w5-lg {    width: 16rem; }

  .u-w-10-lg {  width:  10%; }
  .u-w-16-lg {  width:  16%; }
  .u-w-20-lg {  width:  20%; }
  .u-w-25-lg {  width:  25%; }
  .u-w-30-lg {  width:  30%; }
  .u-w-33-lg {  width:  33%; }
  .u-w-34-lg {  width:  34%; }
  .u-w-40-lg {  width:  40%; }
  .u-w-50-lg {  width:  50%; }
  .u-w-60-lg {  width:  60%; }
  .u-w-70-lg {  width:  70%; }
  .u-w-75-lg {  width:  75%; }
  .u-w-80-lg {  width:  80%; }
  .u-w-90-lg {  width:  90%; }
  .u-w-100-lg { width: 100%; }

  .u-w-third-lg { width: calc(100% / 3); }
  .u-w-two-thirds-lg { width: calc(100% / 1.5); }
  .u-w-auto-lg { width: auto; }
}
@include media-breakpoint-only(xl) { }
